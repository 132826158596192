<template>
  <div class="dailyPaper">
    <div>
      <div class="flex" style="border-top: 1px solid #ccc; padding-top: 10px">
        <div class="title">煤炭产运销趋势图</div>
        <div>
          <!-- <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker> -->
          <el-date-picker
            v-model="value1"
            type="week"
            :format="declareStartDate + ' 至 ' + declareEndDate"
            placeholder="选择周"
            @change="pickerChange"
            :picker-options="{ firstDayOfWeek: 5 }"
            @input="ccc"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div id="mains" style="width: 900px; height: 400px"></div>
      </div>
      <div class="content">
        <div class="lines"></div>
        <div class="flex">
          <div class="cont_title">生产煤矿周报数据录入</div>
          <div style="display: flex">
            <el-date-picker
              style="margin-right: 20px"
              v-model="value2"
              type="week"
              :format="declareStartDates + ' 至 ' + declareEndDates"
              placeholder="选择周"
              @change="pickerChangess"
              :picker-options="{ firstDayOfWeek: 5 }"
              @input="ccc"
            >
            </el-date-picker>
            <div style="text-align: right">
              <el-button type="primary" @click="addPrimary">录入</el-button>
              <el-button @click="exportSubmit">
                <a href="javascript:void(0)" style="color: #333">导出</a>
              </el-button>
            </div>
          </div>
        </div>

        <div style="margin-top: 20px">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column label="操作" width="120px" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.auditStatus == 0"
                  @click="deleteRow(scope.row, tableData)"
                  type="text"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  :disabled="scope.row.auditStatus == null"
                  @click="modifyRecord(scope.row)"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="日期" align="center">
              <template slot-scope="scope">
                {{ scope.row.declareStartDate }}-{{ scope.row.declareEndDate }}
              </template>
            </el-table-column>
            <el-table-column
              prop="rawWeekProduct"
              label="原煤产量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="rawProductCount"
              label="原煤累计产量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="marketWeekProduct"
              label="煤炭销量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="marketProductCount"
              label="煤炭累计销量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="localityMarketProduct"
              label="煤炭本地销量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="transferRailway"
              label="外调煤铁路方向(个)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="railwayTotal"
              label="铁路本周合计运量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="railwayAccumulated"
              label="铁路累计合计运量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="transferHighway"
              label="外调煤公路方向(个)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="highwayTotal"
              label="公路本周合计运量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="highwayAccumulated"
              label="公路累计合计运量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="remark" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus === null">正常</span>
                <span v-if="scope.row.auditStatus == 0">待审核</span>
                <span v-if="scope.row.auditStatus == 1">审核通过</span>
                <span v-if="scope.row.auditStatus == 2">审核拒绝</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="" :visible.sync="dialogVisible" width="800px">
      <div>
        <div
          style="
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            text-align: center;
          "
        >
          <div style="font-size: 19px">周报数据录入</div>
        </div>
        <div style="margin-top: 10px">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <div style="display: flex">
              <el-form-item label="日期" prop="value">
                <el-date-picker
                  v-model="ruleForm.value"
                  type="week"
                  format="yyyy 第 WW 周"
                  placeholder="选择周"
                  @change="pickerChanges"
                  :picker-options="{ firstDayOfWeek: 5 }"
                >
                  <!-- @input="cc" -->
                </el-date-picker>
              </el-form-item>
            </div>
            <div
              style="padding: 10px 0; text-align: center; background: #f6f6f6"
            >
              原煤产量
            </div>
            <div class="flexs" style="margin-top: 20px">
              <el-form-item label="本周产量(万吨)" prop="rawWeekProduct">
                <el-input
                  v-model="ruleForm.rawWeekProduct"
                  placeholder="请输入本周产量"
                ></el-input>
              </el-form-item>
              <el-form-item label="累计产量(万吨)" prop="rawProductCount">
                <el-input
                  v-model="ruleForm.rawProductCount"
                  placeholder="请输入累计产量"
                ></el-input>
              </el-form-item>
            </div>

            <div
              style="padding: 10px 0; text-align: center; background: #f6f6f6"
            >
              煤炭销量
            </div>
            <div class="flexs" style="margin-top: 20px">
              <el-form-item label="本周销量(万吨)" prop="marketWeekProduct">
                <el-input
                  v-model="ruleForm.marketWeekProduct"
                  placeholder="请输入煤炭销量"
                ></el-input>
              </el-form-item>
              <el-form-item label="累计销量(万吨)" prop="marketProductCount">
                <el-input
                  v-model="ruleForm.marketProductCount"
                  placeholder="请输入累计销量"
                ></el-input>
              </el-form-item>
              <el-form-item label="本地销量(万吨)" prop="localityMarketProduct">
                <el-input
                  v-model="ruleForm.localityMarketProduct"
                  placeholder="请输入本地销量"
                ></el-input>
              </el-form-item>
            </div>
            <div
              style="padding: 10px 0; text-align: center; background: #f6f6f6"
            >
              外调煤
            </div>
            <div class="flexs" style="margin-top: 20px">
              <div>
                <span style="font-size: 18px; font-weight: bold; color: black"
                  >铁路</span
                >
                <i
                  class="el-icon-circle-plus"
                  style="font-size: 20px"
                  @click="addDomains"
                ></i>
                <i
                  class="el-icon-remove"
                  style="font-size: 20px"
                  @click="deteleList1"
                ></i>
              </div>
            </div>
            <div
              class="flexs"
              style="margin-top: 20px"
              v-for="(items, index) in list1"
              :key="items.id"
            >
              <el-form-item label="方向" prop="directionId">
                <el-cascader
                  v-model="items.directionId"
                  ref="cascaderItem"
                  :options="citys"
                  placeholder="请选择方向"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                  }"
                  @change="echange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="本周运量(万吨)" prop="total">
                <el-input
                  v-model="items.total"
                  placeholder="请输入本周运量"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flexs" style="margin-top: 20px">
              <div>
                <span style="font-size: 18px; font-weight: bold; color: black"
                  >公路</span
                >
                <i
                  class="el-icon-circle-plus"
                  style="font-size: 20px"
                  @click="addDomain"
                ></i>
                <i
                  class="el-icon-remove"
                  style="font-size: 20px"
                  @click="deteleList"
                ></i>
              </div>
            </div>
            <div
              class="flexs"
              style="margin-top: 20px"
              v-for="(item, index) in list"
              :key="index"
            >
              <el-form-item label="方向" prop="directionId">
                <el-cascader
                  v-model="item.directionId"
                  :options="citys"
                  placeholder="请选择方向"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                  }"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="本周运量(万吨)" prop="total">
                <el-input
                  v-model="item.total"
                  placeholder="请输入本周运量"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AdddialogVisible('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Vue from "vue";
import * as types from "@/store/action-types";
import citys from "@/assets/json/citys";
import moment from "moment";
import {
  findReportWeekList,
  findReportWeekChart,
  addAndEditReportWeek,
  findProductAuto,
  exportReportWeekList,
} from "@/api/mining.js";
import { getTreeV2 } from "@/api/Medium.js";
var isPriceVlidator = (rule, value, callback) => {
  var pattern = /^\d+.?\d{0,2}$/;
  if (value > 214748) {
    return callback(new Error("销售报价金额过大"));
  } else if (value <= 214748 && !pattern.test(value)) {
    return callback(new Error("小数点后最多只能输入两位"));
  } else return callback();
};
export default {
  data() {
    return {
      value2: "",
      value: "",
      value1: "",
      citys: citys,
      ruleForm: {
        value: "",
      },
      dynamicValidateForm: {
        domains: [
          {
            value: "",
          },
        ],
        email: "",
      },
      rules: {
        rawWeekProduct: [
          { required: true, message: "请输入本周产量", trigger: "blur" },
        ],
        value: [{ required: true, message: "请选择日期", trigger: "change" }],
        rawProductCount: [
          { required: true, message: "请输入累计产量", trigger: "blur" },
        ],
        marketWeekProduct: [
          { required: true, message: "请输入本周销量", trigger: "blur" },
        ],
        marketProductCount: [
          { required: true, message: "请输入累计销量", trigger: "blur" },
        ],
        localityMarketProduct: [
          { required: true, message: "请输入本地销量", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      tableData: [],
      value1: "",
      charts: "",
      opinionData: [],
      xdata: [],
      currentPage1: 1,
      total: 100,
      declareStartDates: null,
      declareEndDates: null,
      declareStartDate: null,
      declareEndDate: null,
      list: [
        {
          accumulated: "",
          directionId: "",
          price: "",
          total: "",
        },
      ],
      list1: [
        {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
        },
      ],
      config: {
        pageNum: 1,
        pageSize: 10,
        enterpriseId: Vue.ls.get(types.enterpriseId),
        declareEndDate: null,
        declareStartDate: null,
      },
    };
  },

  mounted() {
    this.getlist();
    this.getecharts();
    this.getTree();
    // this.deleteChildren(this.citys)
  },
  methods: {
    modifyRecord(data) {
      this.$emit("getDetail", data.id);
    },
    echange(e) {
      console.log(e);
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    cc() {
      console.log(this.ruleForm.value);
      let weekOfday = moment(this.ruleForm.value).format("E");
      let Monday = moment(this.ruleForm.value)
        .subtract(weekOfday - 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let Sunday = moment(this.ruleForm.value)
        .add(7 - weekOfday, "days")
        .format("YYYY-MM-DD"); //周日日期
      console.log(Monday, Sunday);
    },
    ccc() {
      console.log(this.value1);
      let weekOfday = moment(this.value1).format("E");
      let Monday = moment(this.value1)
        .subtract(weekOfday - 1, "days")
        .format("YYYY-MM-DD"); //周一日期
      let Sunday = moment(this.value1)
        .add(7 - weekOfday, "days")
        .format("YYYY-MM-DD"); //周日日期
      console.log(Monday, Sunday);
    },
    deleteRow(e, t) {
      if (e.auditStatus == 0) {
        return;
      }
      this.ruleForm = {};
      this.list1 = e.railway;
      this.list = e.highway;
      e.value = e.declareStartDate;
      this.ruleForm = JSON.parse(JSON.stringify(e));
      // this.ruleForm.value=e.declareStartDate
      // this.StartWeek = moment(e.declareStartDate, 'YYYY-MM-DD').toString();
      this.dialogVisible = true;
    },
    AdddialogVisible(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.list1.forEach((item) => {
            if (item.directionId instanceof Array) {
              item.directionId = item.directionId[item.directionId.length - 1];
            }
          });
          this.list.forEach((item) => {
            if (item.directionId instanceof Array) {
              item.directionId = item.directionId[item.directionId.length - 1];
            }
          });
          console.log(this.list1);

          this.ruleForm.railway = this.list1;
          this.ruleForm.highway = this.list;
          addAndEditReportWeek(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.dialogVisible = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.getlist();
              this.getecharts();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    getecharts() {
      this.opinionData = [];
      this.xdata = [];
      let config = {
        pageNum: 1,
        pageSize: 10,
        declareStartDate: this.declareStartDate,
        declareEndDate: this.declareEndDate,
      };
      findReportWeekChart(config).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          let list1 = [];
          let list2 = [];
          let list3 = [];
          list.forEach((item) => {
            list1.push(item.marketWeekProduct);
            list2.push(item.rawWeekProduct);
            list3.push(item.transferCount);
            this.xdata.push(item.key);
          });
          this.opinionData = [
            {
              name: "煤炭销量",
              type: "line",
              stack: "Total",
              data: list1,
            },
            {
              name: "原煤产量",
              type: "line",
              stack: "Total",
              data: list2,
            },
            {
              name: "外调煤总量",
              type: "line",
              stack: "Total",
              data: list3,
            },
          ];

          this.$nextTick(function () {
            this.drawLine("mains");
          });
        }
      });
    },
    getlist() {
      findReportWeekList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          let newlist = childs[i].children;
          for (let j = newlist.length; j--; j > 0) {
            delete newlist[j].children;
          }
        }
      }
      return arr;
    },
    addDomain() {
      let obj = {
        accumulated: "",
        directionId: "",
        price: "",
        total: "",
      };
      console.log(this.list);
      this.list.push(obj);
    },
    addDomains() {
      let obj = {
        directionId: "",
        total: "",
        accumulated: "",
        price: "",
        irectionName: "",
      };
      console.log(this.list1);
      this.list1.push(obj);
    },
    deteleList1() {
      if (this.list1.length > 1) {
        this.list1.pop();
      }
    },
    deteleList() {
      if (this.list.length > 1) {
        this.list.pop();
      }
    },
    exportSubmit() {
      exportReportWeekList(this.config).then((res) => {
        var elink = document.createElement("a");
        elink.download = "周报数据.xls";
        elink.style.display = "none";
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    addPrimary() {
      (this.list = [
        {
          accumulated: "",
          directionId: "",
          price: "",
          total: "",
        },
      ]),
        (this.list1 = [
          {
            directionId: "",
            total: "",
            accumulated: "",
            price: "",
          },
        ]),
        (this.ruleForm = {});
      this.dialogVisible = true;
    },
    pickerChangess(val) {
      console.log(val);
      if (val) {
        let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
        this.declareStartDates = this.fun(timeStamp - 24 * 60 * 60 * 1000); //开始时间
        this.declareEndDates = this.fun(timeStamp + 24 * 60 * 60 * 1000 * 5); //结束时间
        this.config.declareStartDate = this.fun(
          timeStamp - 24 * 60 * 60 * 1000
        ); //开始时间
        this.config.declareEndDate = this.fun(
          timeStamp + 24 * 60 * 60 * 1000 * 5
        ); //结束时间
      } else {
        this.declareStartDates = null;
        this.declareEndDates = null;
        this.config.declareStartDate = null;
        this.config.declareEndDate = null;
      }
      this.getlist();
    },
    pickerChange(val) {
      console.log(val, "ooooooo");
      if (val) {
        let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
        this.declareStartDate = this.fun(timeStamp - 24 * 60 * 60 * 1000); //开始时间
        console.log(this.declareStartDate, "888888");
        this.declareEndDate = this.fun(timeStamp + 24 * 60 * 60 * 1000 * 5); //结束时间
        // this.config.declareStartDate = this.fun(timeStamp - 24 * 60 * 60 * 1000); //开始时间
        // this.config.declareEndDate = this.fun(timeStamp + (24 * 60 * 60 * 1000) * 5); //结束时间
      } else {
        this.declareStartDate = null;
        this.declareEndDate = null;
        // this.config.declareStartDate = null
        // this.config.declareEndDate = null
      }
      this.getecharts();
    },

    // getNowTime() {
    //     let date = new Date()
    //     //本周第一天
    //     let start = new Date(date.getTime() - (3600 * 1000 * 24 * (date.getDay() == 0 ? 6 : date.getDay() - 1)))
    //     //本周最后一天
    //     let end = new Date(date.getTime() + (3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 7 - date.getDay())))
    //     console.log(start, end)
    //     this.value1 = start
    //     //封装示例
    //     let { startTime, endTime } = this.getDate(new Date());
    //     for (var i = 0; i < startTime.length; i++) {
    //         if (startTime[i] == '/') {
    //             startTime = startTime.replace('/', '-'); // 注意替换之后就变成新数组了
    //         }
    //     }

    //     for (var i = 0; i < endTime.length; i++) {
    //         if (endTime[i] == '/') {
    //             endTime = endTime.replace('/', '-'); // 注意替换之后就变成新数组了
    //         }
    //     }
    //     this.formInline.weekStartDate = startTime
    //     this.formInline.weekEndDate = endTime

    //     console.log(this.formInline, this.value1)
    //     console.log('周一：', startTime);
    //     console.log('周日：', endTime);
    // },
    pickerChanges(val) {
      console.log(val, "t11111111111", this.value);
      let _this = this;
      if (val) {
        let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
        this.ruleForm.declareStartDate = this.fun(
          timeStamp - 24 * 60 * 60 * 1000
        ); //开始时间
        this.ruleForm.declareEndDate = this.fun(
          timeStamp + 24 * 60 * 60 * 1000 * 5
        ); //结束时间
        this.ruleForm.value = this.ruleForm.declareStartDate;
      } else {
        this.ruleForm.declareStartDate = null;
        this.ruleForm.declareEndDate = null;
        this.ruleForm.value = "";
      }
    },
    fun(unixtimestamp) {
      var unixtimestamp = new Date(unixtimestamp);
      var year = 1900 + unixtimestamp.getYear();
      var month = "0" + (unixtimestamp.getMonth() + 1);
      var date = "0" + unixtimestamp.getDate();
      var hour = "0" + unixtimestamp.getHours();
      var minute = "0" + unixtimestamp.getMinutes();
      var second = "0" + unixtimestamp.getSeconds();
      return (
        year +
        "-" +
        month.substring(month.length - 2, month.length) +
        "-" +
        date.substring(date.length - 2, date.length)
      );
    },
    handleSizeChange(e) {
      console.log(e, "111111111");
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
      console.log(e, "页数");
    },
    // 绘制柱状图
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));
      this.charts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          backgroundColor: "rgba(0, 0, 0,0.5)",
          borderColor: "#1198B9",
          textStyle: {
            fontSize: 14,
            color: "#FFFFFF",
          },
          formatter: function (params) {
            let relVal = null;
            for (let i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].seriesName +
                ":  " +
                params[i].value +
                "吨;";
            }
            return relVal.slice(9);
          },
        },
        legend: {
          itemHeight: 11,
          itemWidth: 11,
          icon: "roundRect",
          borderRadius: [5, 5, 5, 5],
          right: 30,
          top: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        // toolbox: {
        //     feature: {
        //         saveAsImage: {},
        //     },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xdata,
        },
        yAxis: {
          type: "value",
          name: "万吨",
        },

        series: this.opinionData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 228px;
}

.dailyPaper {
  padding: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
}

.content {
  margin-top: 20px;
}

.cont_title {
  font-size: 23px;
  text-align: left;
}

.flexs {
  display: flex;
  justify-content: space-between;
}

.lines {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
</style>
